import React, { useState } from 'react';

import { CopyModal } from '@/core/components';
import { getPublicEnvironmentConfig } from '@/env/client/config';
import { Button } from '@/ui/controls';
import { Stack } from '@/ui/spacing';
import { BodyCopy, Heading } from '@/ui/typography';

function shouldShowLiveBookingWarning() {
  return (
    getPublicEnvironmentConfig().NEXT_PUBLIC_LIVE_BOOKING_WARNING === 'true'
  );
}

export function LiveBookingWarningModal() {
  const [isOpen, setIsOpen] = useState(true);

  if (!shouldShowLiveBookingWarning()) {
    return null;
  }

  return (
    <CopyModal
      isOpen={isOpen}
      onRequestClose={() => {
        // noop
      }}
      contentMaxWidth={700}
    >
      <Stack s="xs">
        <Heading as="h5" style="heading5">
          {`This preview environment points to staging!`}
        </Heading>
        <Stack s="xs">
          <BodyCopy>
            {`Creating bookings in this preview environment may be made against a live
      instance of Opera. They will be marked accordingly. Please exercise
      extra caution when booking.`}
          </BodyCopy>
        </Stack>
        <Button onClick={() => setIsOpen(false)} variant="destructive">
          {`I understand`}
        </Button>
      </Stack>
    </CopyModal>
  );
}
